
import { React, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import MainHeader from '../../components/MainHeader/MainHeader'
import MainFooter from '../../components/MainFooter/MainFooter'
import CTAButton from '../../components/CTAButton/CTAButton'

import team_davidh from '../../assets/pictures/teambilder/david_heidl2.png'

import { lP, oP, sT, URL_MAINAPI, API_TOKEN } from '../../main.js'

import './About.min.css'
import './MAbout.min.css'
import '../../main.min.css'


export default function About() {

    useEffect(() => {

    }, [])


    return (
        <div className='mainCon' id='mainCon'>
            <section className='about--sec_start'>
                <MainHeader pagetitle="" hideProfile={false} hideMenu={false} />

                <h1>Über die App</h1>
            </section>
            <section className='sec_more'>
                <h2>Unsere Mission</h2>

                <p>
                    <div className='mission_zitat'>
                        <i>" Spielend helfen lernen"</i><b>&nbsp;<br />Motto der THW-Jugend</b>
                    </div><br />
                    Eine gute Ausbildung ist essenziell, um als THW-Helfer/in Menschen gut helfen zu können.<br />
                    Wir wollen, dass jede/r Anwärter/in die Chance hat, sich einfach und effektiv für die Prüfung, aber auch langfristig für die Einsätze zu bilden.
                </p>
                <br /><br /><br />
                <h2>Die App</h2>
                <p>
                    Die thwGAT-App wurde für THW-Helferanwärter/innen entwickelt, die sich noch in der Grundausbildung befinden.
                    Mit der App kannst du für deine anstehende Theorieprüfung lernen und Prüfungen simulieren.<br />
                    Die Prüfungsfragen sind auf dem Stand 2024 v3.5<br />
                    Wir übernehmen keine Haftung für die Richtigkeit der Fragen. <br />
                    Der Fragenkatalog ist geistiges Eigentum der Bundesanstalt Technisches Hilfswerk.
                </p>
                <br /><br /><br />
                <h2>Wer steckt hinter der App?</h2>
                <p>
                    -
                    {/* TODO Mehr Text */}

                </p>
                <div className="aboutProfileCardCont">
                    <div className="aboutProfileCard">
                        <img src={team_davidh}></img>
                        <h2>David Adrian Heidl</h2>
                        <h3>Entwickler</h3>
                        <p>
                            B.Sc. Wirtschaftsinformatik<br />
                            Ich bin seit 2022 als Helfer beim THW OV Memmingen im Trupp UL tätig.<br />
                            <a href="mailto:davidh@tonetwork.de">davidh@tonetwork.de</a>
                        </p>
                    </div>

                </div>
                <br />

            </section>
            <MainFooter />
        </div>
    )
}